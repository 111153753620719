<template>
    <div>
        <Paginate
            :page-count="totalPage"
            :force-page="currentPage"
            :click-handler="myCallback"
            :prev-text="'Prev'"
            :next-text="'Next'"
        >
        </Paginate>
    </div>
</template>

<script>
import Paginate from "vuejs-paginate-next";

export default {
    name: 'Paginations',
    components: {
        Paginate
    },
    data: () => ({
        page: 1,
        options: {
            chunk: 2
        }
    }),
    props: ['offset'],
    computed: {
        currentPage () {
            return parseInt(this.$route.query.page)
        },
        paginationData () {
            return this.$store.state.paginationData
        },
        records () {
            return this.paginationData.records ?  this.paginationData.records : 0
        },
        perPage () {
            return this.paginationData.perPage ?  parseInt(this.paginationData.perPage) : 0
        },
        totalPage() {
            if(this.records <= this.perPage) return 1
            let num = parseInt(this.records / this.perPage)
            let fraction = this.records % this.perPage
            if(fraction !== 0) return num+1
            return num
        }
    },
    methods: {
        myCallback (page) {
            this.$emit('onPageChange', page)
        },
        
        next(page) {
            this.$emit('onPageChange', page)
        },
        
        prev(page){
            this.$emit('onPageChange', page)
        },

        setPage(page) {
            this.forcedPage = page
        }
    }
}
</script>
